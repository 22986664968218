import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.HOME.DASHBOARD',
    moduleName: 'dashboard',
    //permission: [''],
    icon: 'fas fa-home',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'home/dashboard/main',
        title: 'Plazza/Gurús',
        moduleName: 'dashboard',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.MODULES',
    moduleName: '',
    //permission: [''],
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.MASTER',
    moduleName: 'masters',
    //permission: [''],
    icon: 'fas fa-tools',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/',
        title: 'MENUITEMS.MAIN.MASTER_SYSTEM',
        moduleName: 'system',
        //permission: [''],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'superadmin/masters/system/attribute',
            title: 'MENUITEMS.MAIN.ATTRIBUTE',
            moduleName: 'system',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/system/type-order',
            title: 'MENUITEMS.MAIN.ORDER_TYPE',
            moduleName: 'system',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/system/type-person',
            title: 'MENUITEMS.MAIN.PERSON_TYPE',
            moduleName: 'system',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/system/type-document',
            title: 'MENUITEMS.MAIN.DOCUMENT_TYPE',
            moduleName: 'system',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/system/productive-sector',
            title: 'MENUITEMS.MAIN.PRODUCTIVE_SECTOR',
            moduleName: 'system',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/system/type-address',
            title: 'MENUITEMS.MAIN.ADDRESS_TYPE',
            moduleName: 'system',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/system/unit-measurement',
            title: 'MENUITEMS.MAIN.UNIT_MEASUREMENT',
            moduleName: 'system',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/system/social-network',
            title: 'MENUITEMS.MAIN.SOCIAL_NETWORK',
            moduleName: 'system',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/system/forbidden-word',
            title: 'MENUITEMS.MAIN.FORBIDDEN_WORD',
            moduleName: 'system',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.LOCATIONS',
        moduleName: 'location',
        //permission: [''],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'superadmin/masters/location/country',
            title: 'MENUITEMS.MAIN.COUNTRY',
            moduleName: 'location',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/location/location-one',
            title: 'MENUITEMS.MAIN.LOCATION_ONE',
            moduleName: 'location',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/location/location-two',
            title: 'MENUITEMS.MAIN.LOCATION_TWO',
            moduleName: 'location',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/location/location-three',
            title: 'MENUITEMS.MAIN.LOCATION_THREE',
            moduleName: 'location',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.ITEM',
        moduleName: 'item',
        //permission: [''],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'superadmin/masters/item/category',
            title: 'MENUITEMS.MAIN.CATEGORIES',
            moduleName: 'item',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
        ]
      },
      {
        path: '/',
        title: 'MENUITEMS.MAIN.PAYMENT_METHOD',
        moduleName: 'payment',
        //permission: [''],
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        submenu: [
          {
            path: 'superadmin/masters/payment/payment-method',
            title: 'MENUITEMS.MAIN.PAYMENT_METHOD',
            moduleName: 'payment',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/payment/banks',
            title: 'MENUITEMS.MAIN.BANKS',
            moduleName: 'payment',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'superadmin/masters/payment/type-account',
            title: 'MENUITEMS.MAIN.ACCOUNT_TYPE',
            moduleName: 'payment',
            //permission: [''],
            icon: '',
            class: 'ml-sub-sub-menu',
            groupTitle: false,
            submenu: []
          }
        ]
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.BUSINESS_PARTNERS',
    moduleName: 'customers',
    //permission: [''],
    icon: 'fas fa-users',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'superadmin/customers/business-partner',
        title: 'MENUITEMS.MAIN.BUSINESS_PARTNERS',
        moduleName: 'customers',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'superadmin/customers/client',
        title: 'MENUITEMS.MAIN.CLIENTS',
        moduleName: 'customers',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.PROCESS',
    moduleName: 'process',
    //permission: [''],
    icon: 'fas fa-list',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'superadmin/process/order',
        title: 'MENUITEMS.MAIN.ORDERS',
        moduleName: 'process',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.REPORTS',
    moduleName: 'reports',
    //permission: [''],
    icon: 'fas fa-file',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'superadmin/reports/commission',
        title: 'MENUITEMS.MAIN.COMMISSION',
        moduleName: 'reports',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  /*{
    path: '/',
    title: 'MENUITEMS.MAIN.NOTIFICATIONS',
    moduleName: 'reminder',
    //permission: [''],
    icon: 'fas fa-mail-bulk',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'notification/reminder/email',
        title: 'MENUITEMS.MAIN.MAIL',
        moduleName: 'reminder',
        //permission: [''],
        icon: '',
        class: 'ml-sub-sub-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },*/
  {
    path: '',
    title: 'MENUITEMS.MAIN.GENERAL_CONFIGURATION',
    moduleName: '',
    //permission: [''],
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: 'configuration/info-company',
    title: 'MENUITEMS.MAIN.COMPANIES',
    moduleName: 'configuration',
    //permission: [''],
    icon: 'fas fa-building',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.SYSTEM',
    moduleName: '',
    //permission: [''],
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.CONFIGURATION',
    moduleName: 'manage-users',
    //permission: [''],
    icon: 'fas fa-cog',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'system/manage-users/components',
        title: 'MENUITEMS.MAIN.COMPONENTS',
        moduleName: 'manage-users',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/manage-users/permissions',
        title: 'MENUITEMS.MAIN.PERMISSIONS',
        moduleName: 'manage-users',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/manage-users/groups',
        title: 'MENUITEMS.MAIN.GROUPS',
        moduleName: 'manage-users',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'system/manage-users/users',
        title: 'MENUITEMS.MAIN.USERS',
        moduleName: 'manage-users',
        //permission: [''],
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
];
