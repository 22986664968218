import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})

export class BankTlanService {
    
	constructor(private http: HttpClient) {
	}

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
        //Authorization: 'TOKEN '+this.authService.getToken()
    })

    validateAccountBank(data) {
        const url_api = environment.bankTlanAPI + 'api/validate'
        return this.http.post<any>(url_api, data,{ headers: this.headers});
    }

    generatePinBank(data){
        const url_api = environment.bankTlanAPI + 'api/generate'
        return this.http.post<any>(url_api, data,{ headers: this.headers});
    }

    verificarPinBank(data){
        const url_api = environment.bankTlanAPI + 'api/validate_pin'
        return this.http.post<any>(url_api, data,{ headers: this.headers});
    }
}