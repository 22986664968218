import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'home/dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'superadmin',
        loadChildren: () =>
          import('./pages/apps/superadmin/superadmin.module').then(
            (m) => m.SuperadminModule
          )
      },
      {
        path: 'notification',
        loadChildren: () =>
          import('./pages/apps/notification/notification.module').then(
            (m) => m.NotificationModule
          )
      },
      {
        path: 'configuration',
        loadChildren: () =>
          import('./pages/apps/configuration/configuration.module').then(
            (m) => m.ConfigurationModule
          )
      },
      {
        path: 'system/manage-users',
        loadChildren: () => 
          import('./pages/system/manage-users/manage-users.module').then(
            (m) => m.ManageUsersModule
          ),
      },
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabled'  // for one load page, without reload
      // useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
